import {useEffect, useState} from "react";
import {useBeforeUnload} from "react-use";
import {UserCircleIcon} from "@heroicons/react/24/outline";
import {Button} from "../ui/Button";
import Spinner from "../Spinner";
import {toast} from "react-toastify";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import useSocket from "../hooks/useSocket";

export default function ProfileMatcher({next, ...propsBag}) {
  const [socket] = useSocket();
  const [matchedProfile, setMatchedProfile] = useState();
  const [loading, setLoading] = useState(true);
  useBeforeUnload(true, 'You have unsaved changes, are you sure?');

  useEffect(() => {
    setLoading(true);
    window.fetch('/api/profiles/matches', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then(resp => resp.json())
    .then((resp) => {
      const {success, profile} = resp;

      setMatchedProfile(profile);
    }).finally(() => {
      setTimeout(() => setLoading(false), 2000);
    });
  }, []);

  function claimAndNext() {
    socket.emit('profiles:claim', { profileId: matchedProfile.id }, ({success, msg}) => {
      if (!success) {
        toast.error(msg, DEFAULT_TOAST_CONFIG);
        return;
      }

      next({matchedProfile: true, profile: propsBag})();
    });
  }

  function declineAndNext() {
    fetch('/api/profiles/decline', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({matchedUserId: matchedProfile?.id}),
    })
    .then(resp => resp.json())
    .then(() => {
      next({matchedProfile: false})();
    });
  }

  return (
    <div>
      <div className='text-center space-y-4 py-4'>
        <h2 className='text-2xl font-semibold'>Is this you?</h2>
        <div className='text-sm'>
          <div>
            We think we’ve found your profile to help you get started quickly.
          </div>
          <div>
            You will be able to update info later if any of this information is incorrect.
          </div>
        </div>
      </div>

      <div className='w-96 relative rounded mx-auto border-2 border-gray-500 p-6'>
        {
          loading ? (
            <div className='h-96'>
              <div className='flex space-x-3 min-h-96 items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <Spinner className='h-5 w-5' />
                <span>Finding match...</span>
              </div>
            </div>
          ) : (
            <>
              {
                matchedProfile ? (
                  <>
                    <div className='flex items-center justify-start space-x-5'>
                      <UserCircleIcon className='h-10 w-10'/>
                      <div className='text-xl'>{matchedProfile.firstName} {matchedProfile.lastName}</div>
                    </div>
                    <div className='pt-10 gap-8 grid grid-cols-2'>
                      <div className='font-semibold'>Segment</div>
                      {matchedProfile.segment ? <div className='text-sm'>{matchedProfile.segment}</div> : <div className='italic text-gray-500'>Not listed</div>}
                      <div className='font-semibold'>Company</div>
                      {matchedProfile.company ? <div className='text-sm'>{matchedProfile.company}</div> : <div className='italic text-gray-500'>Not listed</div>}
                      <div className='font-semibold'>Role</div>
                      {matchedProfile.role ? <div className='text-sm'>{matchedProfile.role}</div> : <div className='italic text-gray-500'>Not listed</div>}
                      <div className='font-semibold'>Industry Type</div>
                      {matchedProfile.industryType ? <div className='text-sm'>{matchedProfile.industryType}</div> : <div className='italic text-gray-500'>Not listed</div>}
                      <div className='font-semibold'>Position</div>
                      {matchedProfile.position ? <div className='text-sm'>{matchedProfile.position}</div> : <div className='italic text-gray-500'>Not listed</div>}
                    </div>
                  </>
                ) : (
                  <div className='h-96 flex flex-col space-y-5 justify-center items-center'>
                    <span>There was no match found</span>
                    <Button onClick={declineAndNext}>Add profile data</Button>
                  </div>
                )
              }
            </>
          )
        }
      </div>
      {
        loading || !matchedProfile ? null : (
          <div className='w-96 mx-auto pt-10 flex items-center justify-center space-x-5'>
            <Button onClick={claimAndNext}>Yes, this is me</Button>
            <Button onClick={declineAndNext}>No, this isn't me</Button>
          </div>
        )
      }
    </div>
  )
}
