import {ArrowDownIcon, ArrowPathIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {CheckBadgeIcon} from "@heroicons/react/24/solid";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import debounce from "lodash.debounce";
import * as React from "react";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import FilterContext from '../Contexts/FilterContext';
import {getDefaultReactTableConfig} from "../Data";
import useDataApi from "../hooks/useFetchDataApi";
import useSocket from "../hooks/useSocket";
import {DEFAULT_TABLE_FILTERS} from "../reducers/dataSlice";
import {ActionsCell} from "../Switchboard/columns";
import {Button} from "../ui/Button";
import DataTable from "../ui/DataTable";
import DataTableFacetedFilter from "../ui/DataTableFacetedFilter";
import DataTablePaginationBar from "../ui/DataTablePaginationBar";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../ui/Dialog";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/Dropdown";
import {Skeleton} from "../ui/Skeleton";
import UserAvatar from "../UserAvatar";
import {classNames} from "../utils/classes";

function rightAlignCell(value) {
  return (
    <div className='text-right'>{value}</div>
  );
}

const TABLE_FILTER_IDS = ['carrier', 'states', 'naics', 'lob', 'years'];
const TABLE_FILTER_MARSHALS = {
  naics: {
    serialize: (label) => {
      return {...label, value: label.value.padStart(5, '0')};
    },
    deserialize: (value) => {
      return parseInt(value).toString();
    }
  }
}

export default function TableMarketPerformance({filters}) {
  const {filterState, setFilterState} = useContext(FilterContext);
  const [data, setData] = useState([]);
  const [showCompanyInfo, setShowCompanyInfo] = useState();
  const abortControllerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showFindPeopleDialog, setShowFindPeopleDialog] = useState();
  const [paginationState, setPaginationState] = useState({pageIndex: 0, pageSize: 40});
  const [sorting, setSorting] = useState([{id: 'company_name', desc: false}]);
  const [total, setTotal] = useState();

  useEffect(() => {
    getData();
  }, [sorting, paginationState]);

  useEffect(() => {
    setPaginationState({...paginationState, pageIndex: 0});
  }, [filterState]);

  const getData = useMemo(() => {
    return debounce(() => {
      let query = sorting.map(sort => `sort=${sort.id}${sort.desc ? ' DESC' : ''}`).join('&');

      if (abortControllerRef.current) abortControllerRef.current.abort('New query in progress');

      const controller = new AbortController();
      abortControllerRef.current = controller;

      setLoading(true);

      window.fetch(`/api/data/table?${query}&limit=${paginationState.pageSize}&page=${paginationState.pageIndex}`, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        signal: controller.signal,
        body: JSON.stringify({
          filters: {
            ...filterState,
            naics: filterState.naics?.map(TABLE_FILTER_MARSHALS.naics.deserialize),
          }
        }),
      })
      .then(resp => resp.json())
      .then(({data}) => {
        setData(data.data);
        setTotal(data.data.length < paginationState.pageSize ? 1 : data.total || Infinity);
      })
      .then(() => setLoading(false));
    }, 1000);
  }, [sorting, paginationState]);

  const table = useReactTable({
    columns: [
      {
        accessorKey: "company_name",
        header: () => <div className='text-center'>Company</div>,
        cell: ({getValue}) => {
          return (<div className='whitespace-nowrap'>{getValue()}</div>)
        },
        meta: {
          sortable: true,
          headerText: 'Company',
        }
      },
      {
        accessorKey: "cocode",
        header: rightAlignCell('NAIC'),
        cell: ({getValue}) => rightAlignCell(getValue()),
        meta: {
          sortable: true,
          headerText: 'NAIC',
        }
      },
      {
        accessorKey: "dpe",
        header: rightAlignCell('DPE USD M'),
        meta: {
          sortable: true,
          headerText: 'DPE USD M'
        },
        cell: ({getValue}) => {
          return rightAlignCell(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(getValue()));
        }
      },
      {
        accessorKey: "ay_il",
        header: rightAlignCell('AY IL USD M'),
        meta: {
          sortable: true,
          headerText: 'AY IL USD M',
        },
        cell: ({getValue}) => {
          return rightAlignCell(new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(getValue()));
        }
      },
      {
        accessorKey: "lr",
        header: rightAlignCell('AY LR'),
        cell: ({getValue}) => getValue() ? rightAlignCell(`${Number.parseFloat(getValue()).toFixed(2)}%`) : '',
        meta: {
          sortable: true,
          headerText: 'AY LR USD M',
        },
      },
      {
        accessorKey: "brokerage",
        header: rightAlignCell('Brokerage'),
        cell: ({getValue}) => getValue() ? rightAlignCell(`${getValue()}%`) : '',
        meta: {
          sortable: true,
          headerText: 'Brokerage',
        },
      },
      {
        accessorKey: '',
        header: '',
        id: 'findPeople',
        cell: (props) => {
          return (
            <div className='flex justify-end'>
              <Button onClick={(event) => {
                event.stopPropagation();
                setShowFindPeopleDialog(props.row);
              }}>Find people</Button>
            </div>
          )
        },
      },
    ],
    data,
    manualFiltering: true,
    state: {
      sorting: sorting,
      pagination: paginationState,
    },
    initialState: {
      sorting: {
        id: 'company_name',
        desc: false,
      }
    },
    pageCount: Math.ceil(total / paginationState.pageSize),
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    meta: {
      reload: () => {},
      loading,
    },
  });

  const sortableColumns = useMemo(() => {
    return table.getAllColumns().filter(c => !!c.columnDef.meta).map(c => c.columnDef);
  }, [table]);

  const sortedColumn = sortableColumns?.find(c => c.accessorKey === sorting[0].id);

  return (
    <div>
      <DialogFindPeople onClose={() => setShowFindPeopleDialog(undefined)} show={!!showFindPeopleDialog} companyName={showFindPeopleDialog?.original?.company_name} />
      <DialogShowCompanyInfo companyInfo={showCompanyInfo} show={!!showCompanyInfo} onClose={() => setShowCompanyInfo(undefined)} />
      <div className='sm:flex items-center justify-between space-x-2 mt-2 mb-5'>
        <div className='flex items-center flex-wrap gap-2'>
          {
            TABLE_FILTER_IDS.map((id) => {
              if (!filters[id]) {
                return <Skeleton key={`skel_${id}`} className='h-8 w-32 rounded' />;
              }

              let data = filters[id].data;
              const marshals = TABLE_FILTER_MARSHALS[id];

              if (marshals?.serialize) {
                data = data.map(marshals.serialize);
              }

              return (<DataTableFacetedFilter key={`market_perf_filter_${id}`} columnName={id} title={filters[id].label} options={data} />);
            })
          }
          {
            loading ? null : (
              <Button
                variant="ghost"
                className="h-8 px-2 lg:px-3 flex items-center"
                onClick={() => setFilterState(DEFAULT_TABLE_FILTERS)}
              >
                <span>Reset</span>
                <XMarkIcon className="ml-2 h-4 w-4"/>
              </Button>
            )
          }
        </div>
        <div className='sm:flex hidden items-center space-x-2'>
          <div className='px-2 py-1 hover:bg-gray-600 rounded'>
            <ArrowDownIcon onClick={() => setSorting([{...sorting[0], desc: !sorting[0].desc}])} className={classNames(sorting[0].desc ? '' : 'rotate-180', 'transition-transform h-4')} />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div className='flex items-center text-sm font-normal space-x-2'>
                <span>{sortedColumn?.meta?.headerText}</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {
                sortableColumns.map((column) => {
                  return (
                    <DropdownMenuItem key={`column_sort_${column.accessorKey}`} onClick={() => {
                      setSorting([{id: column.accessorKey, desc: false}]);
                      setPaginationState({...paginationState, pageIndex: 0});
                    }}>{column?.meta?.headerText}</DropdownMenuItem>
                  )
                })
              }
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className='flex items-center justify-center space-x-2 text-sm hidden' onClick={getData}>
          <ArrowPathIcon className='h-4' />
          <div>Reload</div>
        </div>
      </div>
      <div className='h-[700px] overflow-y-auto relative'>
        <DataTable onClick={setShowCompanyInfo} table={table} />
      </div>
      <div className='mt-4 space-y-4'>
        <div className='text-sm text-gray-300 font-semibold text-right'>* Data provided by the NAIC</div>
        <DataTablePaginationBar table={table} pageIndex={paginationState.pageIndex}/>
      </div>
    </div>
  )
}

function DialogFindPeople({show, onClose, companyName}) {
  const [people, setPeople] = useState([]);
  const [socket] = useSocket();

  useEffect(() => {
    if (!show) {
      setPeople([]);
      return;
    }

    socket.emit('profiles:find_by_company', {value: companyName}, ({data}) => {
      setPeople(data);
    });
  }, [show]);

  const columns = [
    {
      header: 'Name',
      accessorKey: 'fullName',
      size: 200,
      cell: ({row}) => {
        const {profilePictureUrl: url, firstName, lastName, claimed} = row.original;
        const profileFullName = [firstName, lastName].filter(n => !!n);

        return (
          <div className='flex items-center space-x-2'>
            <UserAvatar className='h-14 w-14 rounded' firstName={firstName} lastName={lastName} avatarUrl={url}  />
            <div className='whitespace-nowrap'>{profileFullName.join(' ')}</div>
            {claimed ? <CheckBadgeIcon className='h-4 shrink-0' /> : null}
          </div>
        );
      },
      meta: {
        displayName: 'Name',
      },
    },
    {
      accessorKey: 'role',
      header: 'Role',
    },
    {
      accessorKey: 'position',
      header: 'Position',
    },
    {
      id: "actions",
      size: 50,
      cell: (props) => <ActionsCell {...props} hideOptions onMessage={onClose} />,
    }
  ];

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data: people,
    meta: {
      reload: () => {},
      loading: false,
    },
  });

  return (
    <Dialog open={!!show} onOpenChange={onClose}>
      <DialogContent className='p-5 max-w-3xl'>
        <DialogHeader>
          <DialogTitle>People at {companyName}</DialogTitle>
        </DialogHeader>
        <div className='max-h-72 overflow-y-auto'>
          <DataTable table={table} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function DialogShowCompanyInfo({show, onClose, companyInfo}) {
  const [getData, loading, apiErrorMessage] = useDataApi();
  const [transformedData, setTransformedData] = useState([]);
  const [dataYear, setDataYear] = useState();

  useEffect(() => {
    if (!companyInfo) return;

    getData(`/api/data/demographics/${companyInfo.cocode}`, 'GET').then(({data: {data}}) => {
      const transformed = [{
        key: 'NAIC',
        value: data.cocode,
      }, {
        key: 'Name',
        value: data.company_name,
      }, {
        key: 'Description',
        value: data.business_type_desc,
      }, {
        key: 'Location',
        value: [data.city, data.state, data.country_name].join(', '),
      }, {
        key: 'Phone',
        value: data.phone,
      }, {
        key: 'Status',
        value: data.company_status_desc,
      }];

      setDataYear(data.year);
      setTransformedData(transformed);
    });
  }, [companyInfo]);

  function close() {
    setTransformedData([]);
    setDataYear(undefined);
    onClose();
  }

  const columns = [{accessorKey: 'key'}, {accessorKey: 'value'}];

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data: transformedData,
    meta: {
      reload: () => {},
      loading: loading || !transformedData.length,
    },
  });

  return (
    <Dialog open={!!show} onOpenChange={close}>
      <DialogContent className='p-5 max-w-3xl'>
        <DialogHeader>
          <DialogTitle>Info for {companyInfo?.company_name}</DialogTitle>
        </DialogHeader>
        <div className='max-h-1/2 overflow-y-auto space-y-4 '>
          <DataTable hideHeader table={table} />
          {dataYear ? <div className='text-right text-gray-400 text-sm'>* Data collected from {dataYear}</div> : null }
        </div>
      </DialogContent>
    </Dialog>
  );
}
