import { useSelector } from "react-redux";
import {Button} from "../ui/Button";
import {Link} from "wouter";
import useUserProfileCompletionCalculation from "./useUserProfileCompletionCalculation";

export default function UserSetupBanner() {
  const {user, setup} = useSelector(state => state.user);
  const [loaded, {percentage}] = useUserProfileCompletionCalculation();

  if (!user.hasLoaded || !setup.loaded || percentage === 100 || !loaded) return null;

  return (
    <div className='w-full px-3 text-sm bg-gray-950 flex items-center justify-between space-x-2'>
      <div className='py-3 flex items-center space-x-4'>
        <div className="relative w-10 h-10 grow">
          <svg className='h-full w-full' viewBox="0 0 100 100">
            <circle className="text-gray-500 stroke-current" strokeWidth="10" cx="50" cy="50" r="40" fill="transparent"></circle>
            <circle className="text-gray-100 progress-ring__circle stroke-current" strokeWidth="10" strokeLinecap="round" cx="50" cy="50" r="40" fill="transparent" strokeDasharray="251" strokeDashoffset={percentage ? `calc(251px - (251px * ${percentage}) / 100)` : '250'}></circle>
          </svg>
        </div>
        <div>
          <div className='font-semibold'>Strengthen your account</div>
          <div>Just a few steps to improve your security and connect more easily.</div>
        </div>
      </div>
      <Link to='/user/setup'>
        <Button variant='link'>{!percentage ? 'Get started' : 'Resume'}</Button>
      </Link>
    </div>
  );
}
