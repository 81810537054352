import {ReactNode, useContext, useEffect, useState} from "react";
import DashboardContext from '../Contexts/DashboardContext';
import * as React from "react"
import {Profile, FilterState} from './types';
import { Button } from '../ui/Button';
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  RowData,
  PaginationState,
} from "@tanstack/react-table";
import {
  Bars3Icon,
} from "@heroicons/react/24/outline";
import useSocket from "../hooks/useSocket";
import DataTableToolbar from "../ui/DataTableToolbar";
import useAppSelector from "../hooks/useAppSelector";
import FilterContext from '../Contexts/FilterContext';
import DataTable from "../ui/DataTable";
import getColumns from "./columns";
import {toast} from "react-toastify";
import { DEFAULT_TOAST_CONFIG } from "../utils/toast";
import {useSessionStorage, useTitle} from "react-use";
import DataTablePaginationBar from "../ui/DataTablePaginationBar";
import {useLocation} from "wouter";

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    reload: () => void
    loading: boolean
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    displayName?: string
    hidden?: boolean
    adminOnly?: boolean
  }
}

const initialColumnVisibility = {lobSpecialty: false, companyLocation: false};

export default function Switchboard({title, type = 'people', ...props}: {title: string, icon: ReactNode, type?: string}) {
  const [profiles, setProfiles] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility);
  const [toolbarFilters, setToolbarFilters] = useState({});
  const [filterState, setFilterState] = useState<any>({});
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [paginationState, setPaginationState] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [pageCount, setPageCount] = useState<number>(1);
  const [loadingProfiles, setLoadingProfiles] = useState<boolean>(false);
  const {user} = useAppSelector(state => state.user);
  const {toggleShowSidebar} = useContext(DashboardContext) as Partial<{toggleShowSidebar: () => void}>;
  const [peopleFilterSessionStorage, setPeopleFilterSessionStorage] = useSessionStorage('people-filters', '{}');
  const [socket] = useSocket();
  const [, navigate] = useLocation();

  useTitle('Fuse - People');

  useEffect(() => {
    function restoreUrlState() {
      const url = new URL(window.location.href);
      const search = url.searchParams.get('query') || peopleFilterSessionStorage;

      if (!search) return;

      try {
        const {filterState: searchFilterState, paginationState, sortingState} = JSON.parse(search);
        if (searchFilterState) setFilterState(searchFilterState);
        if (paginationState) setPaginationState(paginationState);
        if (sortingState) setSortingState(sortingState);
      } catch (error) {
        console.debug('Could not parse query string');
      }
    }

    restoreUrlState();

    window.addEventListener('popstate', restoreUrlState);

    return () => {
      window.removeEventListener('popstate', restoreUrlState);
    }
  }, []);

  useEffect(persistTableState, [sortingState, filterState, paginationState])
  useEffect(loadProfiles, [socket, sortingState, filterState, paginationState]);

  function resetPagination() {
    setPaginationState({...paginationState, pageIndex: 0});
  }

  const table = useReactTable({
    columns: getColumns(user),
    data: profiles,
    meta: {
      reload: loadProfiles,
      loading: loadingProfiles,
    },
    state: {
      sorting: sortingState,
      columnVisibility,
      pagination: paginationState,
      columnPinning: {
        right: ['actions'],
      }
    },
    pageCount,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    onSortingChange: setSortingState,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPaginationState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  function persistTableState() {
    const filterStateJson = JSON.stringify({sortingState, filterState, paginationState});

    const url = new URL(window.location.href);
    url.searchParams.set('query', filterStateJson);
    setPeopleFilterSessionStorage(filterStateJson);
    window.history.pushState({}, '', url);
  }

  function loadProfiles() {
    if (!socket?.connected || !user || loadingProfiles) return;

    setLoadingProfiles(true);

    socket.emit('profiles:get', {
      type,
      sorting: sortingState,
      columnFilters: Object.entries(filterState),
      pagination: paginationState,
    }, ({success, profiles, pageCount, columnFilters}) => {
      setLoadingProfiles(false);

      if (!success) {
        toast.error('Couldn\'t load the contacts at this time. Try again!', DEFAULT_TOAST_CONFIG);
        return;
      }

      const profilesWithConnections = profiles.map((profile: Profile): Profile => {
        const claimPending = profile.userProfileId && !profile.claimed;
        const isOwn = profile.id === user.id;

        return {
          ...profile,
          meta: {
            isOwn,
            canInteract: profile.claimed || !profile.connected || !isOwn,
            claimPending,
          }
        } as Profile;
      });

      setProfiles(profilesWithConnections);
      setToolbarFilters(columnFilters);
      setPageCount(pageCount);
    });
  }

  const pageIndex = table.getState().pagination.pageIndex + 1;

  return (
    <FilterContext.Provider value={{
      filterState,
      toggleSorting: (column) => {
        column.toggleSorting();
        resetPagination();
      },
      setFilterState: (newFilterState: FilterState) => {
        setFilterState(newFilterState);
        resetPagination();
      }
    }}>
      <div className='sm:flex sm:flex-col justify-between h-full space-y-5 p-4'>
        <div className='shrink-0'>
          <div className='flex items-center pb-4'>
            <div className='lg:hidden lg:mr-0 mr-3 hidden' onClick={toggleShowSidebar}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon role='button' className="h-7 w-7" aria-hidden="true"/>
            </div>
            <div className='flex items-center'>
              <div className='flex items-center space-x-2'>
                <div className='[&>svg]:h-5 xl:[&>svg]:h-5'>{props.icon}</div>
                <div className='font-semibold text-lg'>{title}</div>
              </div>
            </div>
          </div>
          <DataTableToolbar columnFilters={toolbarFilters} table={table}/>
        </div>
        <div className='grow overflow-auto'>
          <DataTable onClick={(row: Profile) => navigate(`/people/${row.id}`)} table={table} />
        </div>
        <DataTablePaginationBar table={table} pageIndex={pageIndex} />
      </div>
    </FilterContext.Provider>
  );
}
