import {BookOpenIcon, UsersIcon} from '@heroicons/react/24/outline';
import React, {useEffect, useState} from 'react'
import {Provider} from "react-redux";
import {useFavicon} from "react-use";
import {Redirect, Route, Router, Switch, useLocation} from "wouter";
import logoLines from './assets/logo-lines.png';
import Dashboard from "./Dashboard";
import Data from "./Data";
import RequestsList from "./Switchboard/RequestsList";
import {MyUserProfile} from "./UserProfile/MyUserProfile";
import UserProfile from "./Dashboard/UserProfile";
import Login from "./Login";
import Reset from "./Reset";
import Signup from "./Signup";
import ChatDisplayManager from "./Chat/ChatDisplayManager";
import Spinner from "./Spinner";
import {store} from "./store";
import CsvImporterScreen from "./Switchboard/CsvImporter";
import Switchboard from "./Switchboard/Switchboard";
import UserSetup from "./UserSetup/UserSetup";
import Unsubscribe from "./Unsubscribe";
import './App.css';

function App() {
  useFavicon(logoLines);
  const [path] = useLocation();
  const [user, setUser] = useState();
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    if (path === '/signup') {
      setLoadingUser(false);
      return;
    }

    fetch('/api/me', {method: 'GET'})
      .then((resp) => resp.json())
      .then(({user}) => {
        if (!user) return null;

        setUser(user);
      }).finally(() => {
        setLoadingUser(false);
      });

    function visibilityChanged() {
      window.localStorage.setItem('isActive', !document.hidden);
    }

    window.addEventListener('visibilitychange', visibilityChanged);

    return () => {
      window.removeEventListener('visibilitychange', visibilityChanged);
    }
  }, []);

  const isAdmin = user?.role_type === 'admin';

  if (loadingUser) {
    return (
      <div className='h-full w-full'>
        <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'><Spinner className='h-10' /></div>
      </div>
    )
  }

  return (
    <Provider store={store}>
      <Router>
        <Route path='/unsubscribe' component={Unsubscribe} />
        {
          !user ? (
            <Switch>
              <Route path='/' component={Login} />
              <Route path='/login' component={Login} />
              <Route path='/signup' component={Signup} />
              <Route path='/reset' component={Reset} />
              <Redirect to='/' />
            </Switch>
          ) : (
            <Route>
              <Dashboard>
                <Switch>
                  <Route path='/chat/:activeSpaceId?/:channelId?' component={ChatDisplayManager} />
                  <Route path='/switchboard' nest>
                    {isAdmin ? <Route path='/import' component={CsvImporterScreen} /> : null }
                    <Route path='/people' component={() => <Switchboard title='People' icon={<UsersIcon />} />} />
                    <Route path='/contacts' component={() => <Switchboard title='Contacts' icon={<BookOpenIcon />} type='contacts' />} />
                    <Route path='/requests' component={RequestsList} />
                    <Route path='/people/:userId' component={UserProfile} />
                  </Route>
                  <Route path='/user/profile' component={MyUserProfile} />
                  <Route path='/user/setup' component={UserSetup} />
                  <Route path='/data' component={Data} />
                  <Route path='/data/metric/:metric' component={Data} />
                  <Redirect to='/chat/all' />
                </Switch>
              </Dashboard>
            </Route>
          )
        }
      </Router>
    </Provider>
  );
}

export default App;
