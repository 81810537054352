import {useContext, useState} from "react";
import {useDebounce} from "react-use";
import {Input} from "./Input";
import {Button} from "./Button";
import {XMarkIcon} from "@heroicons/react/24/outline";
import * as React from "react";
import FilterContext from "../Contexts/FilterContext";
import {Table as TanTable} from "@tanstack/table-core/build/lib/types";
import DataTableFacetedFilter from "./DataTableFacetedFilter";
import DataTableViewOptions from "./DataTableViewOptions";
import useAppSelector from "../hooks/useAppSelector";

interface DataTableToolbarProps<TData> {
  table: TanTable<TData>
}

export default function DataTableToolbar<TData>({ table, columnFilters }: DataTableToolbarProps<TData> & { columnFilters: any }) {
  const {filterState, setFilterState} = useContext(FilterContext);
  const [fullNameFilter, setFullNameFilter] = useState<string>('');
  const {user} = useAppSelector(state => state.user);

  useDebounce(() => {
    setFilterState({...filterState, fullName: fullNameFilter});
  }, 500, [fullNameFilter]);

  function reset() {
    setFilterState({});
    setFullNameFilter('');
  }

  const isFiltered = Object.keys(filterState).length || !!fullNameFilter.length;
  const isAdminUser = user?.role_type === 'admin';

  return (
    <div className='space-y-3'>
      <div className="flex sm:flex-1 items-center justify-between sm:space-x-2">
        <Input
          placeholder="Search by name"
          value={fullNameFilter}
          onChange={(event) => setFullNameFilter(event.target.value)}
          className="h-8 xl:w-[350px]"
        />
      </div>
      <div className='flex items-center justify-between'>
        <div className='flex flex-wrap items-center xl:space-y-0 gap-2 pb-2'>
          {
            Object.entries(columnFilters).map(([filterName, {name, data}]: [string, {name: string, data: any}]) => {
              const column = table.getColumn(filterName);

              if ((column.columnDef?.meta?.adminOnly && !isAdminUser) || !column) return null;

              return (
                <DataTableFacetedFilter key={`filter_${filterName}`} columnName={filterName} column={table.getColumn(filterName)} title={name} options={data} />
              );
            })
          }
          {
            isFiltered ? (
              <Button
                variant="ghost"
                onClick={reset}
                className="h-8 px-2 lg:px-3"
              >
                Reset
                <XMarkIcon className="ml-2 h-4 w-4" />
              </Button>
            ) : null
          }
        </div>
        <DataTableViewOptions table={table} />
      </div>
    </div>
  )
}
