import {useMemo} from "react";
import {useSelector} from "react-redux";
import {calculateCompletion} from "./utils";

export default function useUserProfileCompletionCalculation() {
  const {user, setup: {matchedProfile, loaded}} = useSelector(state => state.user);

  const calculation = useMemo(() => calculateCompletion(user, matchedProfile), [user, matchedProfile]);

  return [loaded, calculation, matchedProfile];
}
