import {Table as TanTable} from "@tanstack/table-core/build/lib/types";
import {Profile} from "../Switchboard/types";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "./Table";
import {getCommonPinningStyles} from "../Switchboard/utils";
import {flexRender} from "@tanstack/react-table";
import {Button} from "./Button";
import Spinner from '../Spinner';
import * as React from "react";
import {useLocation} from "wouter";
import {useEffect, useRef} from "react";
import { classNames, cn } from "../utils/classes";

export default function DataTable<TData, TValue>({className, table, onClick, hideHeader = false}: {
  className?: string,
  hideHeader?: boolean,
  onClick?: (row: Profile) => void,
  table: TanTable<Profile>
}) {
  const tableContainerRef = useRef(null);
  const [, navigate] = useLocation();

  const currentPage = table.getState().pagination.pageIndex;

  useEffect(() => {
    if (!tableContainerRef.current) return;

    tableContainerRef.current.scrollTo({top: 0, behavior: 'smooth'});
  }, [currentPage, tableContainerRef]);

  const rows = table.getRowModel().rows;

  return (
    <div className={cn('flex flex-col border rounded-md overflow-hidden h-full relative', className)}>
      <div className="overflow-auto" ref={tableContainerRef}>
        <Table className='relative border-separate border-spacing-x-0 border-spacing-y-1 last:border-spacing-y-0 last:border-none'>
          {
            !hideHeader ? (
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow className='bg-gray-800 sticky top-0 z-40' key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const {column} = header;

                      if (column.columnDef.meta?.hidden) return null;

                      return (
                        <TableHead className='whitespace-nowrap sticky top-0 z-50 border-b border-white' key={header.id} style={{...getCommonPinningStyles(column)}}>
                          {
                            header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                          }
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
            ) : null
          }
          <TableBody className={classNames(table.options?.meta?.loading ? 'opacity-50' : '', 'border-spacing-y-1 last:border-0 relative')}>
            {rows?.length ? (
              rows.map((row, rowIdx) => {
                const isLastRow = rowIdx === rows.length - 1
                const visibleCells = row.getVisibleCells();
                const lastIdx = visibleCells.length - 1;
                const clickable = !!row.original?.id || !!onClick;

                return (
                  <TableRow
                    key={row.id}
                    className={classNames(clickable ? 'cursor-pointer' : '', 'hover:bg-green-500/10 bg-gray-800')}
                    data-state={row.getIsSelected() && "selected"}
                    onClick={() => onClick ? onClick(row.original) : undefined}
                  >
                    {visibleCells.map((cell, cellIdx) => {
                      const {column} = cell;

                      if (column.columnDef.meta?.hidden) return null;

                      return (
                        <TableCell
                          className={classNames(isLastRow ? '' : 'border-b border-white')}
                          style={{...getCommonPinningStyles(column)}} key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={table.getAllColumns().length} className="h-48 text-center space-y-5">
                  {
                    table.options?.meta?.loading ? (
                      <div className='flex items-center justify-center space-x-2'>
                        <Spinner className='h-5'/>
                        <span>Loading...</span>
                      </div>
                    ) : (
                      <>
                        <div>No results.</div>
                        <Button size='sm' onClick={table.options?.meta?.reload}>Refresh</Button>
                      </>
                    )
                  }
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
