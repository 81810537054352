import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts";
import {ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent} from "../ui/Chart";
import {millionsAndBillionsFormatter} from '../utils';
import {classNames} from "../utils/classes";

export default function ChartBar({data, config, xAxisDataKey, className, formatter = millionsAndBillionsFormatter}) {
  return (
    <ChartContainer config={config} className={classNames('print:w-full', className)}>
      <BarChart accessibilityLayer data={data}>
        <CartesianGrid vertical={false}/>
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          tickMargin={5}
          tick={{fill: ''}}
          className='print:fill-black fill-white'
          interval={0}
          textAnchor="end"
          tickFormatter={(value) => value.slice(0, 4)}
        />
        <YAxis
          className='print:fill-black fill-white'
          tickFormatter={(value) => formatter(value, 1)}
          tickLine={false}
          tickMargin={5}
          interval={0}
          tick={{fill: ''}}
          textAnchor="end"
        />
        <ChartTooltip
          cursor={false}
          content={(
            <ChartTooltipContent valueFormatter={(value) => {
              return formatter(value, 3);
            }} indicator="dot"  />
          )}
        />
        {
          Object.values(config).map(({label, color}) => {
            return (
              <Bar
                key={`${label}_${color}`}
                dataKey={label}
                fill={color}
                stroke={color}
                radius={4}
              />
            )
          })
        }
        <ChartLegend content={<ChartLegendContent/>}/>
      </BarChart>
    </ChartContainer>
  );
}
