import {useContext, useMemo} from "react";
import FilterContext from "../Contexts/FilterContext";

export default function useReducedColumns(columnName, labelColumnKey, formatter, extraProps = {}) {
  const {filterState} = useContext(FilterContext);

  return useMemo(() => {
    if (!filterState.years) return [];

    const defaultColumns = [
      {
        accessorKey: labelColumnKey,
        header: '',
        cell: ({getValue}) => {
          return (<div className='whitespace-nowrap'>{getValue()}</div>)
        },
        ...extraProps,
      }
    ];

    filterState[columnName].forEach((col) => {
      defaultColumns.push({
        accessorKey: col.toString(),
        header: col.toString(),
        cell: (cell) => formatter ? formatter(cell.getValue()) : cell.getValue(),
        ...extraProps,
      });
    });

    return defaultColumns;
  }, [filterState]);
}
